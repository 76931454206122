import useTable from "../../../../MainComponents/Hooks/useTable.jsx";
import HelmetTags from "../../../../MainComponents/HelmetTags.jsx";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import useAxios from "../../../../MainComponents/Hooks/useAxios.jsx";
import { useForm } from "react-hook-form";
import Loader from "../../../../MainComponents/Loader.jsx";
import { Col, Row } from "antd";
function Students() {
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const navigate = useNavigate();

  const [successByMobile, errorByMobile, loadingByMobile] = useAxios(
    `${process.env.REACT_APP_BASE_API}api/admin/students/searchbymobile`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Find StudentByMobile",
      dependency: !submitAdd.dependency,
      data,
    });
  };
  useEffect(() => {
    if (successByMobile) {
      navigate(`/admin/students/viewgroup/student/${successByMobile?.data}`);
    }
  }, [successByMobile]);

  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );
  if (loadingCourses) {
    return <Loader />;
  }

  return (
    <section className=" flex flex-col justify-center gap-10   w-full  px-10 py-20  dark:bg-dark dark:text-light sm:px-10">
      <HelmetTags title={`Students | Mr.Ahmed Bayoumy`}></HelmetTags>

      <form
        className="flex items-center justify-center p-5"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="rounded-lg bg-gray-200 p-2 w-1/2 md:w-full">
          <div className="flex">
            <div className="flex w-20 items-center justify-center rounded-tl-lg rounded-bl-lg border-r border-gray-200 bg-white py-5 relative">
              <svg
                viewBox="0 0 20 20"
                aria-hidden="true"
                className="pointer-events-none absolute w-5 fill-gray-500 transition"
              >
                <path d="M16.72 17.78a.75.75 0 1 0 1.06-1.06l-1.06 1.06ZM9 14.5A5.5 5.5 0 0 1 3.5 9H2a7 7 0 0 0 7 7v-1.5ZM3.5 9A5.5 5.5 0 0 1 9 3.5V2a7 7 0 0 0-7 7h1.5ZM9 3.5A5.5 5.5 0 0 1 14.5 9H16a7 7 0 0 0-7-7v1.5Zm3.89 10.45 3.83 3.83 1.06-1.06-3.83-3.83-1.06 1.06ZM14.5 9a5.48 5.48 0 0 1-1.61 3.89l1.06 1.06A6.98 6.98 0 0 0 16 9h-1.5Zm-1.61 3.89A5.48 5.48 0 0 1 9 14.5V16a6.98 6.98 0 0 0 4.95-2.05l-1.06-1.06Z"></path>
              </svg>
            </div>
            <input
              type="text"
              className="w-full py-5 bg-white pl-2 text-2xl font-semibold outline-0"
              placeholder="Mobile"
              id=""
              {...register("mobile", {
                required: true,
              })}
            />
            <button
              type="submit"
              className="bg-secondary p-2 rounded-tr-lg rounded-br-lg text-white font-semibold hover:bg-white hover:text-secondary border-secondary border transition-colors"
            >
              Search
            </button>
          </div>
        </div>
      </form>

      <Row gutter={[16, 16]} justify="center" align="middle">
        {dataCourses?.data?.map((item, idx) => (
          <Col
            key={item.n}
            className="bg-[#FFC107] px-4 py-4 w-fit h-fit hover:bg-[#FFC107]/50 cursor-pointer m-2"
          >
            <Link to={`/admin/students/viewgroup/${item.key}`}>
              <p className=" text-black whitespace-nowrap">{item.name}</p>
            </Link>
          </Col>
        ))}
      </Row>
    </section>
  );
}

export default Students;
