import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../MainComponents";
export default function TrailExam() {
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddAssigment, errorAddAssigment, loadingAddAssigment] =
    useAxios(
      process.env.REACT_APP_ADMIN_ADD_ASSIGMNETS_API,
      "POST",
      submitAdd.flag,
      submitAdd.dependency,
      submitAdd.data,
      true
    );

  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Course",
      dependency: !submitAdd.dependency,
      data,
    });
  };

  const table = useTable("trailexam", "", "", successAddAssigment);

  if (loadingAddAssigment) {
    return <Loader />;
  }
  return (
    <>
      <HelmetTags title="Trail Exams | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light px-10 py-10`}
      >
        <div className="h-auto width flex flex-col items-center mt-6 gap-10">
          <h1 className="text-3xl font-bold">Add New Trail Exam</h1>
          <form
            className="flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-10"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="flex gap-5 w-full items-start flex-col">
              <label >Name</label>
              <input
                type="text"
                placeholder="Add Name"
                className="signin-inputs w-full py-5 "
                {...register("name", {
                  required: true,
                })}
              />
              {errors.name && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>
            <div className="flex gap-5 w-full items-start flex-col">
              <label >Description</label>
              <textarea
                className="signin-inputs w-full py-5 "
                  placeholder="Add Description"
                {...register("description", {
                  required: true,
                })}
              ></textarea>
              {errors.description && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>
            <div className="flex gap-5 w-full items-start flex-col">
              <label >Duration</label>
              <input
                type="number"
                placeholder="Add Duration"
                className="signin-inputs w-full py-5 "
                {...register("duration", {
                  required: true,
                })}
              />
              {errors.duration && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>
            <div className="flex gap-5 w-full items-start flex-col">
              <label >Course</label>
              <select
                placeholder="Choose Course"
                className="signin-inputs w-full "
              >
                {dataCourses?.data?.map((item, idx) => (
                  <option value={item.name} key={item.id}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <button type="submit" className="submit w-full">
              Add
            </button>
          </form>
        </div>

        <div className="h-fit  w-full py-3">{table}</div>
      </section>
    </>
  );
}
