import { useParams } from "react-router-dom";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../MainComponents";
export default function AddAssigment() {
  const { ID } = useParams();

  const [DataAssigments, ErrorAssigments, LoadingAssigment] = useAxios(
    process.env.REACT_APP_STUDENTS_GET_ASSIGMENT_API,
    "GET",
    "GET",
    ""
  );
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddCourse, errorAddCourse, loadingAddCourse] = useAxios(
    `${process.env.REACT_APP_STUDENTS_ADD_ASSIGMENT_API}`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Assigment",
      dependency: !submitAdd.dependency,
      data: {
        student_id: ID,
        assignments: data?.assigments,
      },
    });
    console.log({
      student_id: ID,
      assigments: data?.assigments,
    });
  };
  if (LoadingAssigment) return <Loader />;
  return (
    <main className="px-10 py-10 ">
      <div className="py-10 md:px-2 w-full  flex items-start flex-col justify-center ">
        <h3 className="text-5xl pb-10">Add Assigment</h3>
        <form onSubmit={handleSubmit(onSubmit)} className="   w-full">
          <table className=" w-full ">
            <tbody className="  rounded-full w-full">
              {DataAssigments?.data?.map((item) => (
                <tr
                  key={item.n}
                  className="flex items-start my-2  gap-5 py-3 px-4 first:border-t first:border-t-black border-b  border-black/30 bg-white hover:bg-gray-50 transition duration-200 text-3xl"
                >
                  <td className=" text-center">
                    <p className="text-black">{item.n}</p>
                  </td>
                  <td className="flex items-center gap-2">
                    <input
                      type="checkbox"
                      {...register(`assigments`)}
                      value={item.key}
                      className="cursor-pointer w-5 h-5"
                    />
                    <p className="text-black text-2xl ">{item.name}</p>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {!LoadingAssigment && (
            <button className="bg-secondary px-3 py-3 rounded-md  text-white hover:bg-secondary/50 mt-10">
              Add
            </button>
          )}
        </form>
      </div>
    </main>
  );
}
