import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import useAxios from "../../../../MainComponents/Hooks/useAxios";

export default function Skills() {
  const [sumbitAdd, setSumbitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const [sucessSkillAdd] = useAxios(
    process.env.REACT_APP_SKILLS_ADD_API,
    "POST",
    sumbitAdd.flag,
    sumbitAdd.dependency,
    sumbitAdd.data,
    true
  );

  const onSumbit = (data) => {
    setSumbitAdd({
      flag: "Add Skill",
      dependency: !sumbitAdd.dependency,
      data,
    });
  };
  const table = useTable("skills", "", "", sucessSkillAdd);
  return (
    <>
      <HelmetTags title="Skills | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light px-10 py-10`}
      >
        <div className="h-auto width flex flex-col items-center mt-6 gap-10">
          <h1 className="text-3xl font-bold">Add Skill</h1>
          <form
            onSubmit={handleSubmit(onSumbit)}
            className="flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-10"
          >
            <div className="w-full ">
              <label>Name</label>
              <input
                type="text"
                placeholder="name"
                className="signin-inputs w-full py-5 "
                {...register("name", { required: true })}
              />
              {errors.name && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>
            <div className="w-full">
              <label>Youtube embed</label>
              <input
                type="text"
                placeholder="Link"
                className="signin-inputs w-full py-5 "
                {...register("youtubelink", { required: true })}
              />
              {errors.youtubelink && (
                <p className="text-lg text-bold">the field is required</p>
              )}
            </div>

            <button type="submit" className="submit w-full">
              Add
            </button>
          </form>
        </div>

        <div className="h-fit  w-full py-3 px-6">{table}</div>
      </section>
    </>
  );
}
