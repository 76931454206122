import { Link, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCalculator,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";

import QuizTimer from "./QuizTimer";

const QuizHeader = ({
  state,
  dispatch,
  quizErrors,
  onFinishQuiz,
  quizLoading,
  exam,
}) => {
  const { ID } = useParams();

  return (
    <div>
      <header
        className={`w-full px-10  ${
          state.isShowAnswers ? "h-[80px]" : "h-[120px]"
        } fixed inset-0 bg-white z-50 flex items-center w-full border-b-2 border-b-[hsl(217,19%,27%)] border-dashed  justify-between`}
      >
        <p className="text-[#374151] font-bold whitespace-nowrap">Mr Bayoumy</p>

        {/*           <div className="header-above  h-[32px] w-fit">
            <h4 className="exam-title font-bold">{state?.quizInfo?.title}</h4>
          </div> */}
        {state.isShowAnswers ||
          (state.quizInfo?.duration && (
            <QuizTimer
              duration={state.quizInfo.duration}
              state={state}
              onFinishQuiz={onFinishQuiz}
            />
          ))}

        {/*  {state.isShowAnswers && (
            <Link to={`${exam ? `/exams/${ID}` : `/lectures/sessions/${ID}`}`}>
              <div className="collapse-btn top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md shadow-black/40 duration-300 hover:scale-110 hover:text-light">
                <FontAwesomeIcon
                  className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                    state.collapse && "rotate-180"
                  }`}
                  icon={faChevronRight}
                />
              </div>
            </Link>
          )} */}
        {/*    <div
            className={`header-underneath  flex w-full items-center justify-between gap-[20px] ${
              state.isShowAnswers && "pointer-events-none hidden"
            }`}
          >
            <div className="flex w-1/2 justify-end md:w-fit">
              {!quizErrors && !quizLoading && (
                <div
                  onClick={() => dispatch({ type: "setCollapse" })}
                  className="collapse-btn hover:bg-   top-[96px] flex h-[40px] w-[40px] cursor-pointer items-center justify-center rounded-full bg-accent text-light shadow-md  shadow-black/40 duration-300 hover:scale-110 hover:text-light"
                >
                  <FontAwesomeIcon
                    className={`transition-all duration-300 ease-in-out hover:scale-110 ${
                      state.collapse && "rotate-180"
                    }`}
                    icon={faChevronRight}
                  />
                </div>
              )}
            </div> 
          </div> */}

        <li className="list-none">
          <a href="https://www.desmos.com/calculator" className=" list-none">
            <FontAwesomeIcon
              icon={faCalculator}
              className="text-5xl text-black"
            />
          </a>
        </li>
      </header>
    </div>
  );
};

export default QuizHeader;
