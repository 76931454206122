import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import Loader from "../../../../MainComponents/Loader";

export default function Books() {
  const [clicked, setClicked] = useState(false);
  const [reFetch, setReFetch] = useState(false);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [submitAddBooksAndCourse, setSubmitAddBooksAndCourse] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddBooks, errorAddBooks, loadingAddBooks] = useAxios(
    process.env.REACT_APP_BOOKS_UPDATE_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );

  const [
    successAddBooksAndCourse,
    errorAddBooksAndCourse,
    loadingAddBooksAndCourse,
  ] = useAxios(
    "https://api.natlix.com/api/admin/books/submit/coursebook",
    "POST",
    submitAddBooksAndCourse.flag,
    submitAddBooksAndCourse.dependency,
    submitAddBooksAndCourse.data,
    true
  );

  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );

  const [dataBooks, errorBooks, loadingBooks] = useAxios(
    `${process.env.REACT_APP_BOOKS_GET_API}`,
    "GET",
    "GET",
    reFetch
  );

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmitAddBooksWithCourses = (data) => {
    const formData = {
      books: data.books || [], // Array of selected book keys
      course: data.course, // Selected course key
    };
    setSubmitAddBooksAndCourse({
      flag: "Add Book with courses",
      dependency: !submitAddBooksAndCourse.dependency,
      data: formData,
    });
  };

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Book",
      dependency: !submitAdd.dependency,
      data,
    });
  };

  useEffect(() => {
    if (successAddBooks) {
      setReFetch((prev) => !prev); // Toggle reFetch to reload dataBooks
    }
  }, [successAddBooks]);

  const table = useTable("books", "", "", successAddBooks);

  if (loadingCourses) return <Loader />;

  return (
    <>
      <HelmetTags title="Books | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-start dark:bg-dark dark:text-light py-10 px-10`}
      >
        <div className="h-auto width flex flex-col items-center mt-6">
          <form
            className=" flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
            onSubmit={handleSubmit(onSubmit)}
          >
            <h4 className="text-3xl font-bold text-center w-full">
              Add New Book
            </h4>
            <div className="w-full">
              <div className="relative flex flex-col justify-center items-start gap-2 ">
                <label htmlFor="course">Book Name</label>
                <input
                  type="text"
                  placeholder="Add Book Name"
                  className="signin-inputs w-full"
                  {...register("name", {
                    required: true,
                  })}
                />
              </div>
              {errors.name && (
                <p className="text-red-500 text-xl pt-4">
                  {errors.name.type === "required" && "This field is required."}
                </p>
              )}
            </div>
            <button type="submit" className=" submit max-w-60 mt-10 ">
              Add
            </button>
          </form>
        </div>
        <div className="h-fit w-full py-3">{table}</div>
        {loadingAddBooks ? (
          <Loader />
        ) : (
          successAddBooks && (
            <form
              className="w-full"
              onSubmit={handleSubmit(onSubmitAddBooksWithCourses)}
            >
              <div className="py-10 px-2 w-full">
                <h3 className="text-5xl pb-10">Books</h3>
                <table className="w-full align-top border-black/50 border">
                  <tbody>
                    {!loadingAddBooks &&
                      dataBooks?.data?.map((item) => (
                        <tr
                          key={item.key}
                          className="flex gap-10 py-3 px-2 w-full border-b border-black/50 bg-gray-100/70"
                        >
                          <td>
                            <input
                              type="checkbox"
                              value={item.key}
                              {...register("books", {
                                onChange: (e) => {
                                  const values = e.target.checked
                                    ? [
                                        ...(e.target.form.books.value || []),
                                        e.target.value,
                                      ]
                                    : e.target.form.books.value.filter(
                                        (key) => key !== e.target.value
                                      );
                                  e.target.form.books.value = values;
                                },
                              })}
                            />
                          </td>
                          <td>
                            <p className="text-black">{item.name}</p>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <div className="py-10 px-2 w-full">
                <h3 className="text-5xl pb-10">Courses</h3>
                <table className="w-full align-top border-black/50 border">
                  <tbody>
                    {dataCourses?.data?.map((item) => (
                      <tr
                        key={item.key}
                        className="flex gap-10 py-3 px-2 w-full border-b border-black/50 bg-gray-100/70"
                      >
                        <td>
                          <input
                            type="radio"
                            value={item.key}
                            {...register("course", {
                              required: "Please select a course.",
                            })}
                          />
                        </td>
                        <td>
                          <p className="text-black">{item.name}</p>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="py-10 flex items-center w-full justify-center">
                <button
                  type="submit"
                  className="bg-blue-700 hover:bg-blue-700/50 duration-150 text-white px-4 py-3"
                >
                  Save
                </button>
              </div>
            </form>
          )
        )}
      </section>
    </>
  );
}
