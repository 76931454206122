import HelmetTags from "../../../MainComponents/HelmetTags";
import useTable from "../../../MainComponents/Hooks/useTable.jsx";

function HomePage() {
  const table = useTable("home");

  return (
    <section
      className={`width min-h-screen px-10  py-20 dark:bg-dark dark:text-light `}
    >
      <HelmetTags title="Home | Mr Ahmed Bayoumy"></HelmetTags>
      <div className="p-1 rounded-2xl bg-gradient-to-r from-red-500 via-yellow-500 to-red-500">
        <div className="w-full rounded-2xl  bg-[#212529] shadow-3xl text-center py-6 px-5 space-y-5 text-white mx-auto">
          <h1 className="text-5xl font-semibold py-3">
            Welcome to Mr.Bayoumy`s website.
          </h1>
          <br className="bg-slate-500 text-slate-500 border border-slate-500 "></br>
          <p className="text-white">
            Please <span className="font-bold">select</span> the book that you
            didn't answer before, or <span className="font-bold">Not</span> yet
            finished. (Some books are{" "}
            <span className="font-bold">recommended</span> according to scores
            ). Best wishes.
          </p>
        </div>
      </div>

      <div>{table}</div>
    </section>
  );
}

export default HomePage;
