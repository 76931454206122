import Navbar from "./Navbar";
import Footer from "./Footer";
import { Outlet } from "react-router-dom";

function Layout() {
  return (
    <div className="watermark">
      <div id="top"></div>
      <Navbar />

      <Outlet />
      <Footer />
    </div>
  );
}

export default Layout;
