import { Link } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import useTable from "../../../MainComponents/Hooks/useTable";
import { useState } from "react";
import "../../../SASS/Assignments.scss";
import { Loader } from "../../../MainComponents";
export default function StudentSkills() {
  const [submitRequest, setSubmitRequest] = useState({
    flag: "",
    dependancy: false,
    data: {},
  });

  const [getAssignment, errorAssigment, loadingAssignment] = useAxios(
    "https://api.natlix.com/api/myskills",
    "GET",
    "GET",
    ""
  );
  const [sumbitRequest, errorRequest, loadingRequest] = useAxios(
    "https://api.natlix.com/api/modelanswerskills/requestvideos",
    "POST",
    submitRequest.flag,
    submitRequest.dependancy,
    submitRequest.data,
    true
  );
  const onSubmit = (data) => {
    const finalData = { id: data.key };
    setSubmitRequest({
      flag: "student_skills",
      dependancy: !submitRequest.dependancy,
      data: finalData,
    });
  };
  if (loadingAssignment) return <Loader />;
  return (
    <section className="px-10 py-10 flex flex-col min-h-screen w-full ">
      <table className="tableassignments">
        <tbody>
          {getAssignment?.data?.map((item) => (
            <tr className="py-5 text-blue-500" key={item.key}>
              <td>
                {item.results &&
                item.results[0] &&
                item.results[0].have_taken !== null
                  ? `${item.results[0].dgree} Out OF ${item.results[0].out_of} `
                  : null}
              </td>
              <td>
                {item.results &&
                item.results[0] &&
                item.results[0].have_taken !== null
                  ? `Try ${item.results[0].have_taken}`
                  : null}
              </td>
              <td>{item.name}</td>
              <td>
                <Link
                  to={`exam/start/${item.key}`}
                  className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary text-white duration-150 "
                >
                  {item.n_result === 0 ? "Start" : "Retake"}
                </Link>
              </td>
              <td>
                {item.is_video === 1 ? (
                  <Link
                    to={`/homework/videos/${item.key}`}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border  duration-150 "
                  >
                    Videos
                  </Link>
                ) : null}
                {item.n_YoutubeEmbed > 0 &&
                item.is_req === 1 &&
                item.is_video === 0 ? (
                  <button
                    disabled={
                      item.n_YoutubeEmbed > 0 &&
                      item.n_req === 1 &&
                      item.is_req === 1
                    }
                    onClick={() => onSubmit(item)}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border  duration-150 "
                  >
                    Request
                  </button>
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
