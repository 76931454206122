import { useEffect, useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../MainComponents";
export default function AdminAssigments() {
  const [reftech, setReFetch] = useState(false);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddAssigment, errorAddAssigment, loadingAddAssigment] =
    useAxios(
      process.env.REACT_APP_ADMIN_ADD_ASSIGMNETS_API,
      "POST",
      submitAdd.flag,
      submitAdd.dependency,
      submitAdd.data,
      true
    );

  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  useEffect(() => {
    if (successAddAssigment) {
      reset();
    }
  }, [successAddAssigment]);

  const onSubmit = (data) => {
    setSubmitAdd({
      flag: "Add Assignemts",
      dependency: !submitAdd.dependency,
      data,
    });
    reset();
  };

  const table = useTable(
    "assigments",
    "",
    "",
    successAddAssigment,
    "",
    "",
    false,
    null,
    null,
    null,
    dataCourses
  );

  if (loadingCourses) {
    return <Loader />;
  }
  return (
    <>
      <HelmetTags title="Assigments | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light px-10 py-10`}
      >
        <div className="h-auto width flex flex-col items-center mt-6">
          {!loadingCourses && (
            <form
              className=" flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <h4 className="text-3xl font-bold text-center w-full">
                Add Assigment
              </h4>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label htmlFor="Assignments">Name</label>
                  <input
                    type="text"
                    placeholder="Add Assignemnts Name"
                    className="signin-inputs w-full"
                    {...register("name", {
                      required: true,
                    })}
                  />
                </div>
                {errors.name && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.name.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label htmlFor="course">Description</label>
                  <textarea
                    type="text"
                    placeholder="Description"
                    className="signin-inputs w-full py-5"
                    {...register("description", {
                      required: true,
                    })}
                  />
                </div>
                {errors.description && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.description.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label htmlFor="course">Duration</label>
                  <input
                    type="number"
                    placeholder="Duration"
                    className="signin-inputs w-full"
                    {...register("duration", {
                      required: true,
                    })}
                  />
                </div>
                {errors.duration && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.duration.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label htmlFor="course">Course</label>
                  <select
                    placeholder="Choose Course"
                    className="signin-inputs w-full"
                    {...register("course", { required: true })}
                  >
                    {dataCourses?.data?.map((item, idx) => (
                      <option value={item.key} key={item.id}>
                        {item.name}
                      </option>
                    ))}
                  </select>
                </div>
                {errors.course && (
                  <p className="text-red-500 text-xl pt-4">
                    {errors.course.type === "required" &&
                      "This field is required."}
                  </p>
                )}
              </div>

              <input
                className=" submit max-w-60 mt-10 "
                type="submit"
                value="Submit"
              />
            </form>
          )}
        </div>
        <div className="h-fit  w-full py-3">{table}</div>
      </section>
    </>
  );
}
