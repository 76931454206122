import { useParams } from "react-router-dom";
import useTable from "../../../../MainComponents/Hooks/useTable";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import { Loader } from "../../../../MainComponents";
import { useForm } from "react-hook-form";
import { useState, useEffect } from "react";
import { Line } from "@ant-design/charts";

export default function ViewAssigmentStudent() {
  const { ID } = useParams();
  const [stage, setStage] = useState("");
  const [course, setCourse] = useState("");
  const [datagraph, setDataGraph] = useState({});
  const [show, setShow] = useState(false);
  const [changepassword, setChangepasword] = useState("");
  const [reFetch, setReftech] = useState(false);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [submitChangePassword, setSubmitChangePassword] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [DataStudent, ErrorStudent, LoadingStudent] = useAxios(
    `${process.env.REACT_APP_STUDENTS_VIEWGROUP_ASSIGMENT_API}/${ID}`,
    "GET",
    "GET",
    ""
  );
  const [DataAssigmentStudent, ErrorAssigmentStudent, LoadingAssigmentStudent] =
    useAxios(
      `${process.env.REACT_APP_STUDENTS_VIEWGROUP_ASSIGMENT_API}/results/${ID}`,
      "GET",
      "GET",
      ""
    );

  useEffect(() => {
    if (DataStudent) {
      setStage(DataStudent?.data?.stage || "");
      setCourse(DataStudent?.data?.course || "");
    }
  }, [DataStudent, stage, course]);

  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );

  const [editSuccess, editError, editLoading] = useAxios(
    `${process.env.REACT_APP_BASE_ADMIN_API}students/update`,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    reFetch
  );
  const [ChangePasswordSuccess, ChangePasswordError, ChangePasswordLoading] =
    useAxios(
      `${process.env.REACT_APP_BASE_ADMIN_API}students/changepassword`,
      "POST",
      submitChangePassword.flag,
      submitChangePassword.dependency,
      submitChangePassword.data,
      true
    );

  useEffect(() => {
    if (editSuccess) {
      setReftech(!reFetch);
    }
  }, [editSuccess]);

  useEffect(() => {
    if (!LoadingAssigmentStudent && DataAssigmentStudent) {
      // Map the data for the chart's format
      const processedData = DataAssigmentStudent?.data?.map((item) => ({
        Assigment: item.name,
        Precentage: item.percentage_degree,
      }));

      // Config for the Line chart
      const config = {
        data: processedData,
        xField: "Assigment",
        yField: "Precentage",
        smooth: true, // Optional: makes the line smooth
        point: { size: 5, shape: "diamond" },
        xAxis: {
          label: {
            autoRotate: false, // Disable auto rotation
            style: {
              rotate: Math.PI / 2, // Rotate text 90 degrees
              textAlign: "start",
            },
          },
        }, // Optional: adds points for each data
      };

      setDataGraph(config);
    }
  }, [LoadingAssigmentStudent, DataAssigmentStudent]);

  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const table = useTable(
    "ViewAssigment",
    "",
    "",
    "",
    "",
    "",
    true,
    DataAssigmentStudent?.data
  );
  const onSubmit = (data) => {
    const finalData = {
      id: ID,
      name: data.name,
      username: data.username,
      mobile: data.mobile,
      parent_mobile: data.parent_mobile,
      school: data.school,
      stage: data.stage,
      course: data.course,
    };
    setSubmitAdd({
      flag: "Add Assigment",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };
  const onSubmitChangePassword = (data) => {
    const finalData = {
      id: ID,
      password: data.changepassword,
    };

    setSubmitChangePassword({
      flag: "Add Assigment",
      dependency: !submitChangePassword.dependency,
      data: finalData,
    });
    reset({ changepassword: "" });
  };

  if (LoadingStudent) return <Loader />;
  return (
    <main className="px-10 py-10 ">
      <div className=" h-auto width flex flex-col items-center mt-6 gap-10">
        <h1 className="text-3xl font-bold">Edit {DataStudent?.data?.name}</h1>
        <form
          className="flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label htmlFor="name">Name</label>
              <input
                type="text"
                defaultValue={DataStudent?.data?.name}
                className="signin-inputs w-full"
                {...register("name")}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label htmlFor="usernam">Username</label>
              <input
                type="text"
                defaultValue={DataStudent?.data?.username}
                className="signin-inputs w-full"
                {...register("username")}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label>Mobile</label>
              <input
                type="text"
                defaultValue={DataStudent?.data?.mobile}
                className="signin-inputs w-full"
                {...register("mobile")}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label>Parent Mobile</label>
              <input
                type="text"
                defaultValue={DataStudent?.data?.parent_mobile}
                className="signin-inputs w-full"
                {...register("parent_mobile")}
              />
            </div>
          </div>
          <div className="w-full">
            <div className="relative flex flex-col justify-center items-start gap-2 ">
              <label>School</label>
              <input
                type="text"
                defaultValue={DataStudent?.data?.school}
                className="signin-inputs w-full"
                {...register("school")}
              />
            </div>
          </div>

          {dataCourses && (
            <>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label className="w-fit">Stage</label>
                  <select
                    defaultValue={stage}
                    className="signin-inputs w-full"
                    {...register("stage")}
                    onChange={(e) => setStage(e.target.value)}
                  >
                    <option value={"9"}>Grade 9</option>
                    <option value={"10"}>Grade 10</option>
                    <option value={"11"}>Grade 11</option>
                    <option value={"12"}>Grade 12</option>
                  </select>
                </div>
              </div>
              <div className="w-full">
                <div className="relative flex flex-col justify-center items-start gap-2 ">
                  <label className="w-fit">Course</label>
                  <select
                    defaultValue={course}
                    {...register("course")}
                    onChange={(e) => setCourse(e.target.value)}
                  >
                    {dataCourses?.data?.map((course) => (
                      <option value={course.key}>{course.name}</option>
                    ))}
                  </select>
                </div>
              </div>
            </>
          )}

          <input
            className=" submit max-w-60 mt-10"
            type="submit"
            value="Edit"
          />
        </form>
        <div className=" h-auto width flex flex-col items-center mt-6">
          <h1 className="text-3xl font-bold">Change Password</h1>
          <form
            onSubmit={handleSubmit(onSubmitChangePassword)}
            className="flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6"
          >
            <div className="w-full">
              <div className="relative flex flex-col justify-center items-start gap-2 ">
                <label>Change Password</label>
                <input
                  type="text"
                  {...register("changepassword")} // Corrected typo here
                  className="signin-inputs w-full"
                />
              </div>
            </div>

            <button
              disabled={!isValid || ChangePasswordLoading}
              type="submit"
              className=" submit max-w-60 mt-10"
            >
              Change Password
            </button>
          </form>
        </div>
      </div>

      <div className="py-32 overflow-hidden">{table}</div>

      <div>
        <Line {...datagraph} />
      </div>
    </main>
  );
}
