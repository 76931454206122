import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
export default function Courses() {
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [successAddCourse,errorAddCourse,loadingAddCourse] = 
  useAxios(
    process.env.REACT_APP_ADMIN_ADD_COURSES_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  )
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onTouched" });

  const onSubmit = (data) => {

    setSubmitAdd({
      flag: "Add Course",
      dependency: !submitAdd.dependency,
      data ,
    });
  };

  const table = useTable("courses", "","",successAddCourse);
  return (
    <>
      <HelmetTags title="Courses | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-center dark:bg-dark dark:text-light py-16 px-20`}
      >
        <div className="h-auto width flex flex-col items-center mt-6">
  
        <form  className=" flex flex-col justify-center items-start bg-white p-16  rounded-3xl shadow-3xl w-3/4 my-6 gap-6" onSubmit={handleSubmit(onSubmit)}>
        <h4 className="text-3xl font-bold text-center w-full">Add new Course</h4>
        <div className="w-full">
          <div className="relative flex flex-col justify-center items-start gap-2 ">
            <label htmlFor="course">
              Course Name
            </label>
            <input
              type="text"
              placeholder="Add Course Name"
              className="signin-inputs w-full"
              {...register("name", {
                required: true,
                
              })}
            />
            </div>
            {errors.name && (
            <p className="text-red-500 text-xl pt-4">
              {errors.name.type === "required" && "This field is required."}
            </p>
          )}
          </div>

          <input
          className=" submit max-w-60 mt-10 "
          type="submit"
          value="Submit"
        />
            

        </form>
        </div>
       
        <div className="h-fit  w-full py-3">{table}</div>
      </section>
    </>
  );
}
