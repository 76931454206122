import { Link } from "react-router-dom";

export default function History() {
  return (
    <section className="px-10 pt-20  w-full min-h-screen space-y-5">
      <h1 className="text-4xl font-semibold text-center">Mistakes History</h1>
      <div className=" flex flex-wrap w-full justify-between items-center h-full max-w-[500px] mx-auto">
        <Link
          className="text-white bg-blue-500 hover:bg-secondary rounded-3xl px-4 py-3 duration-200 "
          to={"/mistakehistory/view/assignments"}
        >
          Assignemnts
        </Link>
        <Link
          className="text-white bg-blue-500 hover:bg-secondary rounded-3xl px-4 py-3 duration-200"
          to={"/mistakehistory/view/skills"}
        >
          Skills
        </Link>
        <Link
          className="text-white bg-blue-500 hover:bg-secondary rounded-3xl px-4 py-3 duration-200"
          to={"/mistakehistory/view/weeklycheckpoint"}
        >
          Weekly Check Point
        </Link>
      </div>
    </section>
  );
}
