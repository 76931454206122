const RightQuestionsSidebar = ({ state, dispatch }) => {
  return (
    <div>
      <div
        className={`  flex-wrap flex items-center justify-center  px-[20px] transition-all duration-300 ease-in-out w-full  mb-10 `}
      >
        <div className={` w-full  `}>
          <div className=" w-full mt-12">
            <div className=" w-full flex items-center">
              <div className=" gap-10  w-full !flex flex-wrap items-center justify-center flex-row">
                {state.questions.map((question, i) => (
                  <span
                    key={i}
                    className={`${question.is_selected ? "" : "myquestion"}`}
                    onClick={() =>
                      dispatch({ type: "setCurrentQuestionIndex", payload: i })
                    }
                  >
                    {i + 1}
                  </span>
                ))}
              </div>

              {/* TODO: ADD RESULT CONTAINER */}
              {/* <div
                // ref={resultContainer}
                className="result-container flex h-fit w-full flex-col items-center  gap-[16px]"
              ></div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RightQuestionsSidebar;
