import { Link } from "react-router-dom";
import useAxios from "../../../MainComponents/Hooks/useAxios";
import useTable from "../../../MainComponents/Hooks/useTable";
import "../../../SASS/Assignments.scss";

import { Loader } from "../../../MainComponents";
import HelmetTags from "../../../MainComponents/HelmetTags";
export default function Assignments() {
  const [getAssignment, errorAssigment, loadingAssignment] = useAxios(
    process.env.REACT_APP_STUDENT_ASSIGMNENTS_GET_API,
    "GET",
    "GET",
    ""
  );
  if (loadingAssignment) return <Loader />;
  return (
    <section className="min-h-screen px-10 py-10 flex flex-col  w-full ">
      <HelmetTags title="Home | Mr Ahmed Bayoumy"></HelmetTags>
      <h1 className="text-center text-4xl py-5 font-bold">Assignments</h1>
      <table className="tableassignments">
        <tbody>
          {getAssignment?.data?.map((item) => (
            <tr className="py-5 text-blue-500" key={item.key}>
              <td className="px-5"></td>
              <td>
                <p>{item.name}</p>
                <p className="text-black">{item.description}</p>
              </td>
              <td>
                <Link
                  to={`exam/start/${item.key}`}
                  className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border  duration-150 "
                >
                  {item.n_result === 0
                    ? "Start"
                    : item.n_result > 0 && "Retake"}
                </Link>
              </td>
              <td>
                {item.is_req ? (
                  <button
                    disabled={item.is_req}
                    className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border  duration-150 "
                  >
                    Request
                  </button>
                ) : (
                  item.is_video && (
                    <Link
                      to={`/assignments/videos/${item.key}`}
                      className="bg-blue-500 px-4 py-3 rounded-3xl hover:bg-secondary hover:text-white text-white border  duration-150 "
                    >
                      Videos
                    </Link>
                  )
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </section>
  );
}
