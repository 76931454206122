import { useState } from "react";
import HelmetTags from "../../../../MainComponents/HelmetTags";
import useAxios from "../../../../MainComponents/Hooks/useAxios";
import useTable from "../../../../MainComponents/Hooks/useTable";
import { useForm } from "react-hook-form";
import { Loader } from "../../../../MainComponents";
import { Table, Input, Form } from "antd";

export default function AdminHomeWork() {
  const [skills, setSkills] = useState([]);
  const [courses, setCourses] = useState([]);
  const [submitAdd, setSubmitAdd] = useState({
    flag: "",
    dependency: false,
    data: {},
  });

  const [
    successSaveSkillsAndCourse,
    errorSaveSkillsAndCourse,
    loadingSaveSkillsAndCourse,
  ] = useAxios(
    process.env.REACT_APP_ADMIN_HOMEWORK_ADD_API,
    "POST",
    submitAdd.flag,
    submitAdd.dependency,
    submitAdd.data,
    true
  );
  const [dataCourses, errorCourses, loadingCourses] = useAxios(
    process.env.REACT_APP_ADMIN_GET_COURSES_API,
    "GET",
    "GET",
    ""
  );
  const [dataSkills, errorSkills, loadingSkills] = useAxios(
    process.env.REACT_APP_SKILLS_GET_API,
    "GET",
    "GET",
    ""
  );

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSkills(selectedRows);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const rowSelectionRadio = {
    onChange: (selectedRowKeys, selectedRows) => {
      setCourses(selectedRows);
    },
    getRadioProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };

  const onSubmit = (e) => {
    const finalData = {
      skills: skills.map((skill) => skill.key), // map to get only the ids of selected skills
      course: courses[0]?.key, // get the key of the selected course
    };
    console.log(finalData);
    setSubmitAdd({
      flag: "Add SaveSkillsAndCourses",
      dependency: !submitAdd.dependency,
      data: finalData,
    });
  };
  const SkillsHomeworkColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editTable: true,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
    },
  ];
  const CoursesHomeworkColumns = [
    {
      title: "Name",
      dataIndex: "name",
      editTable: true,
      width: 200,
      align: "center",
      sorter: (a, b) => a.name.length - b.name.length,
    },
  ];

  function editableCell({
    editing,
    dataIndex,
    title,
    record,
    children,
    ...restProps
  }) {
    const input = <Input />;
    return (
      <td {...restProps}>
        {editing ? (
          <Form.Item
            name={dataIndex}
            style={{ margin: 0 }}
            rules={[{ required: false }]}
          >
            {input}
          </Form.Item>
        ) : (
          children
        )}
      </td>
    );
  }
  const homeworktable = useTable(
    "homework",
    "",
    "",
    successSaveSkillsAndCourse
  );
  const skillstable = useTable(
    "skillshomework",
    "",
    "",
    "",
    "",
    "",
    true,
    dataSkills?.data
  );
  const coursesstable = useTable(
    "courseshomework",
    "",
    "",
    "",
    "",
    "",
    true,
    dataCourses?.data
  );

  if (loadingSkills || loadingCourses || loadingSaveSkillsAndCourse)
    return <Loader />;
  return (
    <>
      <HelmetTags title="HomeWork | Mr.Ahmed Bayoumy"></HelmetTags>
      <section
        className={`flex h-auto w-full flex-col items-start dark:bg-dark dark:text-light px-10 py-10`}
      >
        <div className="h-fit  w-full py-3">{homeworktable}</div>

        <div className="py-10 px-2 w-full ">
          <h3 className="text-5xl pb-10">Skills</h3>
          <div>
            <Table
              dataSource={dataSkills?.data}
              columns={SkillsHomeworkColumns}
              pagination={{ position: ["bottomCenter"] }}
              bordered
              components={{ body: { cell: editableCell } }}
              rowSelection={{ type: "checkbox", ...rowSelection }}
            />
          </div>
        </div>
        <div className="py-10 px-2 w-full">
          <h3 className="text-5xl pb-10">Courses</h3>
          <div>
            <Table
              dataSource={dataCourses?.data}
              columns={CoursesHomeworkColumns}
              bordered
              pagination={{ position: ["bottomCenter"] }}
              components={{ body: { cell: editableCell } }}
              rowSelection={{ type: "radio", ...rowSelectionRadio }}
            />
          </div>
        </div>
        <div className="mt-4 flex w-full items-center justify-center gap-10 md:flex-col md:items-center">
          <button
            className="w-fit rounded-2xl border-2 border-secondary bg-secondary px-3 py-1 text-center font-semibold text-light duration-300 hover:bg-light hover:text-secondary active:scale-90"
            disabled={skills.length <= 0 && courses.length <= 0}
            onClick={() => onSubmit()}
          >
            save
          </button>
        </div>
      </section>
    </>
  );
}
