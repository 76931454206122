import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const QuizTimer = ({ state, duration, onFinishQuiz }) => {
  const { ID } = useParams();
  const [hide, setHide] = useState(false);
  const initialDuration =
    localStorage.getItem(`Quiz${ID}${state?.quizInfo.title}`) <= 0
      ? duration * 60
      : localStorage.getItem(`Quiz${ID}${state?.quizInfo.title}`);
  const [timerDuration, setTimerDuration] = useState(initialDuration);
  const [formattedTime, setFormattedTime] = useState("");

  useEffect(() => {
    const timer = setInterval(() => {
      setTimerDuration((prevDuration) => prevDuration - 1);
    }, 1000);

    if (timerDuration <= 0) {
      onFinishQuiz();
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [timerDuration]);

  // set time duration in localstorage
  useEffect(() => {
    localStorage.setItem(`Quiz${ID}${state?.quizInfo.title}`, timerDuration);

    localStorage.setItem(`Quiz${ID}${state?.quizInfo.title}FromAPI`, duration);
  }, [ID, duration, state?.quizInfo.title, timerDuration]);

  useEffect(() => {
    if (timerDuration === 0) {
      // console.log("Timer ended!"); // Replace this with your desired code or action
    } else {
      const minutes = Math.floor(timerDuration / 60);
      const seconds = timerDuration % 60;
      const formatted = `${minutes.toString().padStart(2, "0")} min : ${seconds
        .toString()
        .padStart(2, "0")} sec`;
      setFormattedTime(formatted);
    }
  }, [timerDuration]);

  return (
    <div className=" flex w-full flex-col items-center justify-center md:w-fit">
      {hide ? (
        <div className="text-[#374151] font-bold">{formattedTime}</div>
      ) : (
        <button
          onClick={() => setHide(!hide)}
          className="bg-[#374151] text-white px-4 py-2 rounded"
        >
          Show Timer
        </button>
      )}
      {hide && (
        <button
          onClick={() => setHide(!hide)}
          className="bg-[#374151] text-white px-4 py-2 rounded"
        >
          Hide Timer
        </button>
      )}
    </div>
  );
};

export default QuizTimer;
